/* Custom styles for the product gallery to preserve original aspect ratio */

/* Style for the container */
.preserve-aspect-ratio {
  width: 100%;
}

/* Main image container */
.preserve-aspect-ratio .image-gallery-slide img {
  object-fit: contain !important;
  max-height: 600px;
  width: auto;
  margin: 0 auto;
  background-color: #f9f9f9;
}

/* Thumbnail images */
.preserve-aspect-ratio .image-gallery-thumbnail-image {
  object-fit: contain !important;
  height: 80px;
  background-color: #f9f9f9;
}

/* Fullscreen mode */
.image-gallery-fullscreen-button .image-gallery-fullscreen-icon {
  width: 24px;
  height: 24px;
}

/* Make sure the thumbnails row has enough height */
.image-gallery-thumbnails-container {
  height: 90px;
}

/* Add a subtle border to images */
.image-gallery-image {
  border-radius: 4px;
}

/* Ensure the container has proper padding */
.product-gallery {
  padding: 10px 0;
} 